var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("div", { staticClass: "qr-code-container vh-center" }, [
        _vm.imgSrc
          ? _c("img", { attrs: { src: _vm.imgSrc, alt: "qrCode" } })
          : _c("div", { staticClass: "qr-error-text" }, [
              _c(
                "div",
                { attrs: { "data-icon": "error" } },
                [
                  _c("vue-material-icon", {
                    attrs: { name: "error", size: 40 },
                  }),
                ],
                1
              ),
              _vm._m(0),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 유효하지 않은 QR 코드입니다. "),
      _c("br"),
      _vm._v(" 관리자에게 문의하세요. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }