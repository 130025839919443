<template>
  <div class="qr-code-container vh-center" v-if="!isLoading">
    <img :src="imgSrc" alt="qrCode" v-if="imgSrc" />
    <div class="qr-error-text" v-else>
      <div data-icon="error">
        <vue-material-icon name="error" :size="40" />
      </div>
      <p>
        유효하지 않은 QR 코드입니다.
        <br />
        관리자에게 문의하세요.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'QrCode',
  props: [],
  data() {
    return {
      value: '',
      imgSrc: '',
      isLoading: true,
    };
  },
  computed: {},
  created() {
    this.value = encodeURIComponent(this.$route.query.value);
    this.getQrCode();
  },
  mounted() {},
  watch: {},
  methods: {
    ...mapActions('users', ['GET_QR_CODE']),
    async getQrCode() {
      try {
        this.isLoading = true;
        this.imgSrc = await this.GET_QR_CODE(this.value);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import 'QrCode';
</style>
